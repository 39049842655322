<template>
  <base-section id="portal-features">
    <base-section-heading title="Admin Portal Features">
     Here is a short list of features you can expect in the administration portal.
    </base-section-heading>

		<v-container class="mx-auto">
			<v-row>
				<v-col
					v-for="(feature, i) in features"
					:key="i"
					cols="12"
					md="6"
				>
					<base-avatar-card
						v-bind="feature"
						align="left"
						horizontal
					>
					{{ feature.text }}
					</base-avatar-card>
				</v-col>
			</v-row>
		</v-container>

  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
			features: [
				{
					color: 'primary',
					dark: true,
					title: 'Patient Emails',
					icon: 'fa-envelope',
					text: 'Email Patients with a buy-now button / link that will direct them to a payment page.',
				},
				{
					color: 'primary',
					dark: true,
					title: 'Patient Invoices',
					icon: 'fa-file-invoice-dollar',
					text: 'The ability to email patients an invoice auto generated by the system. (Or you can attach existing invoice).',
				},
				{
					color: 'primary',
					dark: true,
					title: 'User Management',
					icon: 'fa-users',
					text: 'Easily add / delete/ modify users access to the system.'
				},
				{
					color: 'primary',
					dark: true,
					title: 'Reporting',
					icon: 'fa-file-alt',
					text: 'With the database of recorded transactions, you have the ability to generate reports.'
				},
				{
					color: 'primary',
					dark: true,
					title: 'White Label Branding',
					icon: 'fa-copyright',
					text: 'The customer facing site is branded to your business identity. Your logo, your, name, your money.',
				},
				{
					color: 'primary',
					dark: true,
					title: 'Record Keeping',
					icon: 'fa-database',
					text: 'Each transaction is logged in the system, allowing you to search and filter transactions.',
				},
			],
    }),
  }
</script>
